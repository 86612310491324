import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Modal from "react-modal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Orbit from "../components/orbit"

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
	},
}

const IndexPage = () => {
	const [isModalOpen, setModalOpen] = React.useState(false)

	return (
		<Layout>
			<SEO title="Home" description="A Front End Developer's Portfolio" lang="en" />
			<Modal
				isOpen={isModalOpen}
				// onAfterOpen={this.afterOpenModal}
				onRequestClose={() => setModalOpen(!isModalOpen)}
				style={customStyles}
				contentLabel="Example Modal"
			>
				<div className="modal-content">
					<span className="close" onClick={() => setModalOpen(!isModalOpen)}>
						&times;
					</span>
					<h2>About Me</h2>
					<p className="text">
						I am Front-end Developer with 5 years of experience. I primarly focus on Javascript(ES5/6, node
						and browser), HTML5, and CSS3(SCSS). I enjoy learning new Javascript/CSS frameworks and
						libraries. I have experice with;
						<ul>
							<li>React Native</li>
							<li>ReactJS, VueJS</li>
							<li>D3JS</li>
							<li>Docker</li>
							<li>AWS</li>
							<li>Bootstrap, Bulma</li>
						</ul>
					</p>
				</div>
			</Modal>
			<div className="container--gradient" id="parent">
				{/* <svg xmlns="http://www.w3.org/2000/svg" version="1.1" className="canvas--svg">
					<defs>
						<filter id="goo">
							<feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
							<feColorMatrix
								in="blur"
								mode="matrix"
								values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 60 -9"
							/>
						</filter>
					</defs>
				</svg> */}
				<Orbit />
				{/* <canvas id="canvas">
					
				</canvas> */}
			</div>
			<div className="container--block flex">
				<div className="inner-block">
					<h1>Daniel Gonzalez</h1>
					<h2>Front-End Developer</h2>
					<p className="typewriter">Check out my new project! </p>
					<div className="btn--container">
						<button className="btn" id="myBtn" onClick={() => setModalOpen(!isModalOpen)}>
							About Me
						</button>
						<a href="mailto:dan@danielgc.design" class="btn btn--gradient">
							Get In Touch
						</a>
					</div>
					<div className="container--icon">
						<a
							href="https://codepen.io/dan10gc/"
							target="_blank"
							alt="Codepen Profile"
							className="link--icon codepen"
						>
							<FontAwesomeIcon icon={["fab", "codepen"]} />
						</a>

						<a
							href="https://www.instagram.com/dan10gc/?hl=en"
							target="_blank"
							alt="Instagram Profile"
							className="link--icon instagram"
						>
							<FontAwesomeIcon icon={["fab", "instagram"]} />
						</a>
						<a
							href="https://twitter.com/Dan10GC"
							target="_blank"
							alt="Twitter Profile"
							className="link--icon twitter"
						>
							<FontAwesomeIcon icon={["fab", "twitter"]} />
						</a>
						<a href="#" target="_blank" alt="LinkedIn Profile" className="link--icon linkedin">
							<FontAwesomeIcon icon={["fab", "linkedin"]} />
						</a>
					</div>
					{/* <p>Welcome to your new Gatsby site.</p>
        <p>Now go build something great.</p>
        <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
          <Image />
        </div> */}
					{/* <Link to="/page-2/">Go to page 2</Link> */}
				</div>
			</div>
		</Layout>
	)
}

export default IndexPage
